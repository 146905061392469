import React from "react";
import { PageContainer } from "../../containers/PageContainer";
import styles from "./About.module.scss";
import highlighter2 from "../../assets/highLighter2.svg";
import about from "../../assets/about.png";

export const About = () => {
  return (
    <PageContainer>
      <div className="w-[90%] pb-[5em] flex flex-col items-center">
        <div className={styles.belowContainer}>
          <p className={styles.headingBelowContent}>About Us</p>
          <img src={highlighter2} className={styles.headingBelowContentImage} />
        </div>

        <p className={styles.facilitySubText}>
          Do you ever wonder how much food goes to waste every year? Nearly a
          third of all food for human consumption in the world is either lost or
          wasted. Inevitably, rampant food waste worsens global hunger.
        </p>
        <p className={styles.facilitySubText}>
          Universal Basic Food &trade; targets the basic necessity of a person which
          is food and works on the universal concept of how most of the world’s
          population is still deprived of this basic necessity. Universal Basic
          Food is here to solve that problem for people in USA. The core mission
          is to eradicate hunger by channeling otherwise wasted food from
          restaurants and other places and putting it to use by providing it to
          recipients in need. We simply connect businesses that have surplus
          food with people who need it. To ease the process, we have developed a
          dedicated smartphone application, called UBF &trade;, that offers options to
          re-allocate surplus food from businesses (restaurants, hotels, super
          markets) – thus, saving it from ending up in the trash bin. Instead,
          it is converted into a fresh free meal at the tap of a finger.
          Together, we’re working to make sure no individual goes to sleep with
          a hungry stomach.
        </p>
        <p className={styles.facilitySubText}>
          Universal Basic Food &trade; primarily exists to alleviate hunger in USA by
          providing a dependable and consistent source of food for the hungry
          through cooperation with a network of food partners/businesses via the
          UBF &trade; application. By partnering with UBF &trade;, restaurants and other donors
          are able to reach those who are in dire need of food and we are
          proud/happy to be a reliable medium for them. At UBF &trade;, we have a vision
          where no edible food in decent condition should go to waste while
          people are in a desperate state of hunger.
        </p>
        <p className={styles.facilitySubText}>
          UBF &trade; is the tech platform operated by Universal Basic Food &trade; to enable
          this mission and bring it into action/bring it to live. It comprises
          of an application where users in need of food can find participating
          partners so they can pick warm meals that are free of cost from any of
          those. There will be a large network of businesses that people can
          select from, depending on their locality and preference. The main
          purpose behind this is for those in need to put their mobile devices
          to the right use and strive to feed themselves. UBF &trade; caters to every
          individual who is actively searching for food, from any race,
          background and ethnicity as we believe the face of hunger is
          Universal.
        </p>
        <br />
        <p className={`${styles.width_80} text-[1.8rem] font-bold text-black text-center`}>How UBF Works </p>
        <img className={"mt-10"} src={about} />
      </div>
    </PageContainer>
  );
};
