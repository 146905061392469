import React from "react";
import styles from "./Footer.module.scss";
import logo from "../../../assets/logoWhite.svg";
import logoText from "../../../assets/logoTextWhite.svg";
import facebook from "../../../assets/facebook.svg";
import instagram from "../../../assets/instagram.svg";
import twitter from "../../../assets/twitter.svg";
import { SmallButton } from "../../Buttons/SmallButton";
import { Link, withRouter } from "react-router-dom";
import { DONATE, SIGNUP } from "../../../routesMap";
import { useHistory } from "react-router-dom";

export const Footer = withRouter((props) => {
  const history = useHistory();

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div className={styles.leftSideContainer}>
          <div
            role={"button"}
            onClick={() => history.push("/")}
            className={styles.leftSide}
          >
            <img src={logo} alt={"logo"} />
            &nbsp; &nbsp;
            <img src={logoText} alt={"logoText"} />
          </div>
          <div className={styles.leftSideBottom}>
            <p className={styles.menuBoxItem}>Find us on</p>

            <div className={styles.socialContainer}>
              <img src={facebook} className={styles.socialsFirst} />
              <img src={twitter} className={styles.socials} />
              <img src={instagram} className={styles.socials} />
            </div>
          </div>
        </div>

        <div className={styles.menuBoxContainer}>
          <div className={styles.menuBox}>
            <p>Get to know us</p>
            <span role={"button"} onClick={() => history.push("/about")}>
              About Us
            </span>
            <span>Meet the team</span>
            <span>Careers</span>
          </div>

          <div className={styles.menuBox}>
            <p>Connect</p>
            <span>Contact Us</span>
            <span>FAQ</span>
          </div>

          <div className={styles.menuBox}>
            <p>Get Involved</p>
            <span>Fundraise</span>
            <span>Restaurant Partnership</span>
            <span>Spread the word</span>
          </div>

          <div className={styles.menuBox}>
            <p>Donate</p>
            <span>Feed someone today</span>
            <span>Give in someone’s honor</span>
          </div>

          <div className={styles.menuItemsBox}>
            <p
              onClick={() => props.history.push(DONATE)}
              className={styles.menuBoxItem}
            >
              Donate
            </p>
            <p className={styles.menuBoxItemSpace}>For ReCiPIENTS</p>
            <p className={styles.menuBoxItemSpace}>For restaurants</p>
            <p
              onClick={() => props.history.push(SIGNUP)}
              className={styles.menuBoxItemSpace}
            >
              Sign up
            </p>
            <Link style={{ textDecoration: "none" }} to={DONATE}>
              <SmallButton text={"Donate"} classname={styles.button} />
            </Link>
          </div>
        </div>
      </div>

      <div className={`border-t border-white pt-[25px] ${styles.container}`}>
        <p className={"text-white text-[14px] w-[80%] text-justify"}>
          © 2022 | charity: food Universal Basic Food, ZIP 82801-6317 | 30 N
          Gould Street Suite 4000, Sheridan, WY | a US 501 (c)(3) public
          charity, EIN 853134260 . Universal Basic Foods is a dual-qualified
          charity registered in United States of America
        </p>
      </div>
    </div>
  );
});
